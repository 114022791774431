import React, { Component } from "react";
import { Translation } from 'react-i18next';


export default class Card extends Component {
    
    render() {
        
        return (
            
            <section className="card_local  py-lg-2 container">
                <div className="row justify-content-center mt-5">
                    <div className="col-12 col-md-12 col-lg-4 mx-0 mb-4">
                        <div className="card p-0 overflow-hidden h-100 shadow">
                            <img src={this.props.img} className="card-img-top" />
                            <div className="card-body text-center">
                            <Translation>
      {
        (t, { i18n }) => ([
        <h6 className="card-title">{t('card_local')}</h6>,
        <h3 style={{ fontFamily: 'Dancing Script',fontSize: '40px'}} className="card-title">"{t('card_name')}"</h3>,
        <h5 className="card-title">{t('data')}{t('ora')}</h5>,
        <p className="card-text">{this.props.adress}</p>,
        <a href={this.props.harta} class="btn btn-primary">{t('harta')}</a>
    ])
       
      }
    </Translation>
                                
                                
                                
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8 mx-0 mb-4">
                        <div className="card p-0 overflow-hidden h-100 shadow">
                            <iframe className="card-img-top h-100" src={this.props.iframe} ></iframe>
                            

                        </div>
                        
                    </div>
                </div>
            </section>
        )
    }
}

