import React, { Component } from "react";
import '../css/Header.css'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Language } from "@mui/icons-material";
import Lang from "./Lang";
import { Translation } from 'react-i18next';


export default class Header extends Component {
    constructor () {
        super();
        this.state={
           swow: true,
        };

    }
    render () {
        return (
        
<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
  <div className="container-fluid px-5">
  {/**  <a className="navbar-brand"  href="#">{this.props.mire} & {this.props.mireasa}</a>*/} 
    <Language/><Lang/>
    <button className="navbar-toggler border text-dark border-dark" 
    onClick={()=>{this.setState({show: !this.state.show})}}>
      {this.state.show ?  <CloseIcon/> : <MenuIcon/>}
    </button>
    
    <div className={this.state.show ? 'collapse navbar-collapse  active bg-white text-center'  : 'collapse navbar-collapse text-center'}>
      <ul className="navbar-nav ms-auto">
        
          <Translation>
         {(t, { i18n }) => ([ 
 <li className="nav-item">
          <a className="nav-link text-dark"  href="#header">Noi</a>
        </li>,
        <li className="nav-item">
          <a className="nav-link text-dark"  href="#invitatie">{t('invitatie')}</a>
        </li>,
        <li className="nav-item">
          <a className="nav-link text-dark" href="#geolocal">{t('unde_cand')}</a>
        </li>
          ])} 
          </Translation>
         
        {/* 
        <li className="nav-item">
          <a className="nav-link text-dark" href="#forms">Confirmare</a>
        </li>*/}
      </ul>
    </div>
  </div>
</nav> 
     
        )

 }}