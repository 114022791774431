import React from "react"
import "../css/ImgInvitatie.css"
import noi from "../images/n.jpeg"
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { WhatsApp } from "@mui/icons-material";
import data from "../Data"
import PhoneIcon from '@mui/icons-material/Phone';
import { useTranslation, initReactI18next } from "react-i18next";




const CardInvitatie = () => {
    const { t } = useTranslation();
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>{t('invitatie')}</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text mb-5">
                            <h5>
                            {t('invitatie_text_1')}
                                <br />
                                {t('invitatie_text_2')}
                                 <br />
                                 {t('invitatie_text_3')}
                               
                            </h5>
                            <h4>Dragoș & Sofia</h4>
                            <h5>
                            {t('invitatie_text_4')}
                                
                            </h5>
                            <h4>
                                Marin & Laura
                            </h4>
                            <h5>
                                {t('invitatie_text_5')}
                                <br />
                                {t('invitatie_text_5_1')} <br />
                                {t('invitatie_text_5_2')}  <br />
                            </h5>
                            <h4>{t('invitatie_text_6')}</h4>
                            <h4>{t('invitatie_text_7')}</h4>
                            <h5>Via del Guado, 20832 Desio (MB), Italia</h5>
                            <br />
                            <h5>{t('invitatie_text_8')}</h5>


                        </div>


                        <h4 class="card-footer">{t('cu_drag')}</h4>

                        {data.introData.map((item) => {
                            return (


                                <div class="card text-center container" style={{ maxWidth: "55rem" }}>
                                    <div class="" >
                                        <b>
                                        {t('invitatie_text_9')}   <br /> {t('invitatie_text_10')} 
                                        
                                      
                                        </b>

                                    </div>

                                    <br />


                                    <div class="mb-3">
                                    {t('invitatie_text_11')}
                                       <br />{t('invitatie_text_11_1')}
                                      <br />

                                        {item.mire} & {item.mireasa} {item.tel} : <br /><a class=" m-2 btn_logo" href={item.phone}> <PhoneIphoneIcon /> Phone</a>
                                        <a className="m-2 btn_logo" href={item.viber}> <PhoneIcon />Viber</a>
                                        <a className="m-2  btn_logo" href={item.whatsapp}><WhatsApp />WhatsApp</a>

                                    </div>
                                </div>)
                        })}

                        {/*<a href="#forms" className="btn btn-primary">Confirmați Prezența</a> */}

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;